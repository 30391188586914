/* eslint-disable radix */
// routes
import { paths } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_API_URL || '';

export const GOOGLE_MAP_API = process.env.REACT_APP_GOOGLE_MAP_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard/app'

// LOGO
// ----------------------------------------------------------------------

export const LOGO = {
  LIGHT: process.env.REACT_APP_LIGHT_LOGO,
  DARK: process.env.REACT_APP_DARK_LOGO,
};

// LANG
export const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG || 'en';

// IMAGE
// ----------------------------------------------------------------------

export const CAMPAIGN_IMAGE_PATH = process.env.REACT_APP_CAMPAIGN_ASSETS;
export const USER_ASSETS_PATH = process.env.REACT_APP_USER_ASSETS || '';
export const CHAT_ASSETS_PATH = process.env.REACT_APP_CHAT_ASSETS;
export const USER_IG_STORY_ASSETS_PATH = process.env.REACT_APP_USER_IG_STORY_ASSETS || '';

//
// ----------------------------------------------------------------------
export const DEBOUNCE_DELAY = parseInt(`${process.env.DEBOUNCE_DELAY}`) || 1000;
