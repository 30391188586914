import Box from '@mui/material/Box';
import { Theme, SxProps } from '@mui/material/styles';

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps<Theme>;
};

export default function InstagramStoriesIcon({ sx }: Props) {
  return (
    <Box
      component="img"
      src="/assets/icons/socials/ic_instagram_stories.svg"
      sx={{ width: 24, height: 24, flexShrink: 0, ...sx }}
    />
  );
}
