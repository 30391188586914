import { noCase } from 'change-case';

import { MediaTypeForOrder } from 'src/redux/coreApi';
import {
  BlogIcon,
  TiktokIcon,
  YoutubeIcon,
  InstagramIcon,
  YoutubeShortsIcon,
  InstagramReelsIcon,
  InstagramStoriesIcon,
} from 'src/assets/icons';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export function getIconFile(name: string) {
  return (
    <Image src={`/assets/icons/files/${name}.svg`} alt={name} sx={{ width: 28, height: 28 }} />
  );
}

// ----------------------------------------------------------------------

export function getFlagIcon(code: string) {
  return `https://flagcdn.com/${code.toLowerCase()}.svg`;
}

// ----------------------------------------------------------------------

export function getSocialFormatIcon(social?: MediaTypeForOrder | 'instagram' | 'youtube') {
  let icon;

  switch (social) {
    case 'instagram':
    case 'instagramPost':
    case 'instagramVideo':
    case 'instagramCarousel':
      icon = <InstagramIcon />;
      break;
    case 'instagramReel':
      icon = <InstagramReelsIcon />;
      break;
    case 'instagramStory':
      icon = <InstagramStoriesIcon />;
      break;
    case 'tiktokVideo':
      icon = <TiktokIcon />;
      break;
    case 'blog':
      icon = <BlogIcon />;
      break;
    case 'youtubeShorts':
      icon = <YoutubeShortsIcon />;
      break;
    case 'youtube':
    case 'youtubeVideo':
      icon = <YoutubeIcon />;
      break;
    default:
      icon = <YoutubeIcon />;
  }

  return icon;
}

// ----------------------------------------------------------------------

export function getCampaignStatusIcon(status: string) {
  let icon;

  switch (noCase(status)) {
    case 'pending':
      icon = <Iconify icon="eva:clock-fill" sx={{ color: 'error.main' }} />;
      break;
    case 'ready to ship':
      icon = <Iconify icon="ic:round-launch" sx={{ color: 'warning.dark' }} />;
      break;
    case 'rejected':
      icon = <Iconify icon="eva:minus-square-fill" sx={{ color: 'error.dark' }} />;
      break;
    case 'shipped':
      icon = <Iconify icon="eva:eye-fill" sx={{ color: 'warning.main' }} />;
      break;
    case 'received':
      icon = <Iconify icon="eva:home-fill" sx={{ color: 'error.dark' }} />;
      break;
    case 'published':
      icon = <Iconify icon="eva:share-fill" sx={{ color: 'info.main' }} />;
      break;
    case 'validated':
      icon = <Iconify icon="mdi:check-decagram" sx={{ color: 'success.main' }} />;
      break;
    case 'timed out':
    case 'timedout':
      icon = (
        <Iconify icon="material-symbols:hourglass-bottom-rounded" sx={{ color: 'text.primary' }} />
      );
      break;
    default:
      icon = <Iconify icon="eva:close-circle-fill" sx={{ color: 'text.primary' }} />;
  }

  return icon;
}
