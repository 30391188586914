import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { useSelector } from 'src/redux/store';
import { selectCurrentUser } from 'src/redux/slices/auth';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const user = useSelector(selectCurrentUser);

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!user) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={paths.auth.login} />;
  }

  if (user?.status && user?.status !== 'active' && pathname !== '/account-validation') {
    return <Navigate to="/account-validation" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
