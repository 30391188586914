import { useLocation } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';

import { useLocales } from 'src/locales';
import { useLogoutMutation } from 'src/redux/coreApi';
import { dispatch, useSelector } from 'src/redux/store';
import { logout, selectCurrentUser } from 'src/redux/slices/auth';

import Logo from 'src/components/tribz-logo';

import LanguagePopover from '../_common/language-popover';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function SimpleLayout({ children }: Props) {
  const { pathname } = useLocation();

  const user = useSelector(selectCurrentUser);

  const { translate } = useLocales();

  const [doLogout] = useLogoutMutation();

  const router = useRouter();

  const handleLogout = async () => {
    try {
      await doLogout();
      dispatch(logout());
      router.push(paths.auth.login);
    } catch (error) {
      console.error(error);
    }
  };

  const pathsDashboard = [paths.auth.login, paths.auth.register, paths.auth.resetPassword];

  const showLogo = !pathsDashboard.includes(pathname);

  return (
    <>
      <HeaderStyle>
        {showLogo && <Logo />}

        <Stack direction="row" flexGrow={1} spacing={1} justifyContent="flex-end">
          <LanguagePopover />

          {!!user && (
            <Button onClick={handleLogout} variant="text" sx={{ m: 0 }} size="small">
              {translate('logout')}
            </Button>
          )}
        </Stack>
      </HeaderStyle>

      {children}
    </>
  );
}
