import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { paths } from 'src/routes/paths';

import { useSelector } from 'src/redux/store';
import { selectCurrentUser } from 'src/redux/slices/auth';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const user = useSelector(selectCurrentUser);

  if (user && user.status !== 'incomplete') {
    return <Navigate to={paths.dashboard.root} />;
  }

  return <>{children}</>;
}
