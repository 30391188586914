import { enUS as muiEnUS, frFR as muiFrFR, zhCN as muiZhCN } from '@mui/material/locale';
import { enUS as dataGridEnUS, frFR as dataGridFrFR, zhCN as dataGridZhCN } from '@mui/x-data-grid/locales';

import { DEFAULT_LANG } from 'src/config-global';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: muiEnUS,
    dataGrid: dataGridEnUS,
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'French',
    value: 'fr',
    systemValue: muiFrFR,
    dataGrid: dataGridFrFR,
    icon: 'flagpack:fr',
  },
  {
    label: 'Chinese',
    value: 'cn',
    systemValue: muiZhCN,
    dataGrid: dataGridZhCN,
    icon: 'flagpack:cn',
  },
];

export const defaultLang = allLangs.filter((lang) => lang.value === DEFAULT_LANG)[0];
