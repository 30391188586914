import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { HOST_API } from 'src/config-global';

import { logout,tokenReceived } from './slices/auth';
// ----------------------------------------------------------------------

// initialize an empty API service that we'll inject endpoints into later as needed

const baseQuery = fetchBaseQuery({
  baseUrl: HOST_API,
  prepareHeaders: (headers, { getState }) => {
    const state: any = getState();
    const token = state?.auth?.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401 && api.endpoint !== 'login') {
    const refreshToken = localStorage.getItem('refreshToken');
    const id = localStorage.getItem('userId');

    // try to get a new token
    const refreshResult = await baseQuery(
      { url: '/manage/refresh-token', method: 'POST', body: { id, refreshToken } },
      api,
      extraOptions
    );

    if (refreshResult.data) {
      // store the new token
      api.dispatch(tokenReceived(refreshResult.data));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }
  return result;
};

export const emptySplitApi = createApi({
  baseQuery: baseQueryWithReauth,
  // keepUnusedDataFor: 1,
  endpoints: () => ({}),
});
