import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { DEFAULT_LANG } from 'src/config-global';

//
import format from './i18n-format';
//
import enLocales from './langs/en.json';
import cnLocales from './langs/cn.json';
import frLocales from './langs/fr.json';

// ----------------------------------------------------------------------

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      fr: { translations: frLocales },
      cn: { translations: cnLocales },
    },
    lng: localStorage.getItem('i18nextLng') ?? DEFAULT_LANG,
    fallbackLng: DEFAULT_LANG,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      format,
    },
  });

export default i18n;
