// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  page404: '/404',
  page500: '/500',
  auth: {
    root: ROOTS.AUTH,
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/signup`,
    resetPassword: `${ROOTS.AUTH}/reset-password`,
    verify: `${ROOTS.AUTH}/verify`,
  },
  dashboard: {
    root: ROOTS.DASHBOARD,
    chat: {
      root: `${ROOTS.DASHBOARD}/chat`,
      new: `${ROOTS.DASHBOARD}/chat/new`,
      conversation: `${ROOTS.DASHBOARD}/chat/:conversationKey`,
    },
    news: {
      root: `${ROOTS.DASHBOARD}/news`,
    },
    users: {
      root: `${ROOTS.DASHBOARD}/users`,
      new: `${ROOTS.DASHBOARD}/users/new`,
    },
    brands: {
      root: `${ROOTS.DASHBOARD}/brands`,
      new: `${ROOTS.DASHBOARD}/brands/new`,
    },
    clubs: {
      root: `${ROOTS.DASHBOARD}/clubs`,
      new: `${ROOTS.DASHBOARD}/clubs/new`,
    },
    campaigns: {
      root: `${ROOTS.DASHBOARD}/campaigns`,
      new: `${ROOTS.DASHBOARD}/create-campaigns`,
    },
  },
};
