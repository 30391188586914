import {
  USER_ASSETS_PATH,
  CHAT_ASSETS_PATH,
  CAMPAIGN_IMAGE_PATH,
  USER_IG_STORY_ASSETS_PATH,
} from 'src/config-global';

// ----------------------------------------------------------------------

export function campaignImageName(path?: string) {
  if (!path) {
    return '';
  }

  return `${path}`.replaceAll(`${CAMPAIGN_IMAGE_PATH}`, '');
}

export function campaignImage(path?: string | null) {
  if (!path) {
    return '';
  }

  if (path.includes('https://')) {
    return path;
  }

  return `${CAMPAIGN_IMAGE_PATH}${campaignImageName(path)}`;
}

export function userImageName(path?: string) {
  if (!path) {
    return path;
  }

  return `${path}`.replaceAll(`${USER_ASSETS_PATH}`, '');
}

export function userStoryImage(paths?: string[] | string | null) {
  const path = Array.isArray(paths) ? paths[0] : paths;

  if (!path) {
    return '';
  }

  if (path.includes('https://')) {
    return path;
  }

  return `${USER_IG_STORY_ASSETS_PATH}${path}`;
}

export function userImage(paths?: string[] | string | null) {
  const path = Array.isArray(paths) ? paths[0] : paths;

  if (!path) {
    return '';
  }

  if (path.includes('https://')) {
    return path;
  }

  return `${USER_ASSETS_PATH}${userImageName(path)}`;
}

export function chatImageName(path?: string) {
  if (!path) {
    return path;
  }

  return `${path}`.replaceAll(`${CHAT_ASSETS_PATH}`, '');
}

export function chatImage(paths?: string[] | string | null) {
  const path = Array.isArray(paths) ? paths[0] : paths;

  if (!path) {
    return '';
  }

  if (path.includes('https://')) {
    return path;
  }

  return `${CHAT_ASSETS_PATH}${chatImageName(path)}`;
}
