import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import SimpleLayout from 'src/layouts/simple';
import { PATH_AFTER_LOGIN } from 'src/config-global';

import { SplashScreen } from 'src/components/loading-screen';

import { authRoutes } from './auth';
import { errorRoutes } from './error';
import { dashboardRoutes } from './dashboard';
// ----------------------------------------------------------------------

const ValidationPage = lazy(() => import('../../pages/validation-page'));

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },
    {
      element: (
        <SimpleLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </SimpleLayout>
      ),
      children: [
        {
          path: 'account-validation',
          element: <ValidationPage />,
        },
        {
          path: 'account-validation/:step',
          element: <ValidationPage />,
        },
      ],
    },

    ...authRoutes,

    ...dashboardRoutes,

    ...errorRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
