import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'src/redux/store';
import { DEFAULT_LANG } from 'src/config-global';
import { selectCurrentUser } from 'src/redux/slices/auth';
import { Lang, usePatchManageUsersByUserIdLangMutation } from 'src/redux/coreApi';

import { allLangs, defaultLang } from './config-lang';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();

  const user = useSelector(selectCurrentUser);

  const langStorage = localStorage.getItem('i18nextLng') || DEFAULT_LANG;

  const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;

  const [updateLang] = usePatchManageUsersByUserIdLangMutation();

  const handleChangeLanguage = useCallback(
    (newlang: string) => {
      if (user?.id) {
        updateLang({ userId: user.id, body: { lang: newlang as Lang } });
      }
      i18n.changeLanguage(newlang);
    },
    [i18n, updateLang, user]
  );

  useEffect(() => {
    i18n.changeLanguage(langStorage);
  }, [langStorage, i18n]);

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLangs,
  };
}
