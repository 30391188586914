import Box from '@mui/material/Box';
import { Theme, SxProps } from '@mui/material/styles';
import Avatar, { AvatarProps } from '@mui/material/Avatar';

import { getSocialFormatIcon } from 'src/utils/get-icon';

import { MediaTypeForOrder } from 'src/redux/coreApi';

// ----------------------------------------------------------------------

interface Props extends AvatarProps {
  format?: MediaTypeForOrder;
  sx?: SxProps<Theme>;
}

export default function SocialAvatar({ format, sx, ...other }: Props) {
  const height = 48;
  let width = 48;

  if (format === 'instagramStory' || format === 'instagramReel') {
    width = (48 * 9) / 16;
  }

  return (
    <Box sx={{ position: 'relative', height: 48, flexShrink: 0, ...sx }}>
      <Box sx={{ position: 'absolute', top: -8, right: -8, zIndex: 9 }}>
        {getSocialFormatIcon(format)}
      </Box>

      <Avatar sx={{ borderRadius: 1, width, height, maxWidth: 48, maxHeight: 48 }} {...other} />
    </Box>
  );
}
