import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';

import { useLocales } from 'src/locales';
import { useLogoutMutation } from 'src/redux/coreApi';
import { dispatch, useSelector } from 'src/redux/store';
import { logout, selectCurrentUser } from 'src/redux/slices/auth';

import { useSnackbar } from 'src/components/snackbar';
import { MyAvatar } from 'src/components/custom-avatar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

export default function AccountPopover() {
  
  const popover = usePopover();

  const { translate } = useLocales();

  const router = useRouter();

  const user = useSelector(selectCurrentUser);

  const createOptions = (user: any) => [
    {
      label: 'home',
      linkTo: '/',
    },
    {
      label: 'profile',
      linkTo: `/dashboard/users/${user?.id}/edit`,
    },
    {
      label: 'settings',
      linkTo: '#',
    },
  ];

  const OPTIONS = createOptions(user);

  const [doLogout] = useLogoutMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  const handleLogout = async () => {
    try {
      await doLogout();
      dispatch(logout());
      router.push(paths.auth.login);
      popover.onClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(translate('snackbar_logout_error'), { variant: 'error' });
    }
  };

  return (
    <>
      <IconButton
        onClick={popover.onOpen}
        sx={{
          p: 0,
          ...(popover.open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {translate(option.label)}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {translate('logout')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
