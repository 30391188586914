import { useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

import { LOGO } from 'src/config-global';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();

  const logo = (
    <Box
      component="img"
      src={theme.palette.mode === 'light' ? LOGO.LIGHT : LOGO.DARK}
      sx={{ height: 18, display: 'inline-block', ...sx }}
    />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink href="/">{logo}</RouterLink>;
}
