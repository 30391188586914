import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { useLocales } from 'src/locales';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const popover = usePopover();

  const locales = useLocales();

  const handleChangeLang = (newLang: string) => {
    locales.onChangeLang(newLang);
    popover.onClose();
  };

  return (
    <>
      <IconButton
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Iconify icon={locales.currentLang.icon} sx={{ borderRadius: 0.65, width: 28 }} />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 180 }}>
        <Stack spacing={0.75}>
          {locales.allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === locales.currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </CustomPopover>
    </>
  );
}
