/* eslint-disable no-unsafe-optional-chaining */
import { noCase } from 'change-case';
import { useRef, useState, useEffect, useCallback } from 'react';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Badge from '@mui/material/Badge';
import { ListItem } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import { useRouter } from 'src/routes/hook';

import { fToNow } from 'src/utils/format-time';

import { useLocales } from 'src/locales';
import { useSelector } from 'src/redux/store';
import { selectCurrentAuth } from 'src/redux/slices/auth';
import { useNotificationContext } from 'src/contexts/notification-context';
import {
  useGetGeneralStatisticsQuery,
  useGetManageNotificationsQuery,
  usePutManageNotificationsReadMutation,
} from 'src/redux/coreApi';

import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

type NotificationItemProps = {
  id?: string;
  title?: string;
  description?: string;
  avatar?: string;
  type?: string;
  createdAt?: string;
  readAt?: string;
  urlParams?: Record<string, string>;
};

export default function NotificationsPopover() {
  const popover = usePopover();

  const { translate } = useLocales();

  const { user } = useSelector(selectCurrentAuth);

  const scrollRef = useRef<HTMLDivElement>(null);

  const { data = {}, isLoading, refetch } = useGetGeneralStatisticsQuery();

  const [notifications, setNotifications] = useState<NotificationItemProps[]>([]);

  const [cursor, setCursor] = useState<string | undefined>();

  const [lastItemId, setLastItemId] = useState<string | undefined>();

  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  const [totalUnread, setTotalUnread] = useState(0);

  const [readNotifications] = usePutManageNotificationsReadMutation();

  const [pusherChannel, setPusherChannel] = useState<any>(null);

  const handleMarkAllAsRead = () => {
    readNotifications({
      body: {
        notificationIds: notifications.filter(({ readAt }) => !readAt).map(({ id = '' }) => id),
      },
    });
    setNotifications(
      notifications.map((n) => (!n.readAt ? { ...n, readAt: new Date().toString() } : n))
    );

    refetch();
  };

  const pusher = useNotificationContext();

  useEffect(() => {
    if (pusher && user?.id) {
      const channel = pusher.subscribe(`private-notifications-${user.id}`);

      setPusherChannel(channel);
    }

    return () => {
      if (pusher && user?.id) {
        pusher.unsubscribe(`private-notifications-${user.id}`);
      }
    };
  }, [pusher, user]);

  useEffect(() => {
    if (pusherChannel?.bind) {
      pusherChannel.unbind('new_notification');
      pusherChannel.bind('new_notification', (data: any) => {
        console.log('new_notification', data);
        setNotifications((prev) => [data, ...prev]);
        refetch();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pusherChannel]);

  const handleScroll = useCallback(() => {
    if (
      scrollRef.current &&
      scrollRef.current?.scrollTop + scrollRef.current?.clientHeight >
        scrollRef.current?.scrollHeight - 50
    ) {
      console.log('Load more');
      if (lastItemId) {
        setCursor(lastItemId);
      }
    }
  }, [lastItemId]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.removeEventListener('scroll', handleScroll);
      scrollRef.current.addEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (!isLoading) {
      setTotalUnread(data.unreadNotifications ?? 0);
    }
  }, [isLoading, data]);

  return (
    <>
      <IconButton
        color={popover.open ? 'primary' : 'default'}
        onClick={popover.onOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnread} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" />
        </Badge>
      </IconButton>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('notifications')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translate('notification_total_unread', { totalUnread })}
            </Typography>
          </Box>

          {totalUnread > 0 && (
            <Tooltip title={translate('mark_all_as_read')}>
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box
          sx={{
            height: 90 * 5,
            overflowX: 'hidden',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {!notifications.length && firstLoad ? (
            <Typography variant="subtitle1">No notifications</Typography>
          ) : (
            <NotificationItems
              notifications={notifications}
              setNotifications={setNotifications}
              cursor={cursor}
              setLastItemId={setLastItemId}
              firstLoad={firstLoad}
              setFirstLoad={setFirstLoad}
              readNotifications={readNotifications}
              closePopover={popover.onClose}
            />
          )}
        </Box>
      </CustomPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItems({
  notifications,
  setNotifications,
  cursor,
  setLastItemId,
  firstLoad,
  setFirstLoad,
  readNotifications,
  closePopover,
}: Readonly<{
  notifications: NotificationItemProps[];
  setNotifications: (items: NotificationItemProps[]) => void;
  setLastItemId: (id?: string) => void;
  firstLoad: boolean;
  setFirstLoad: (loaded: boolean) => void;
  cursor?: string;
  readNotifications: any;
  closePopover: VoidFunction;
}>) {
  const { data = [], isSuccess } = useGetManageNotificationsQuery({ limit: '5', cursor });
  const router = useRouter();

  useEffect(() => {
    if (isSuccess) {
      const newItems = data.filter((item) => !notifications.find((noti) => noti.id === item.id));
      setNotifications([...notifications, ...newItems]);

      if (newItems.length > 0) {
        setLastItemId(newItems[newItems.length - 1]?.id);
      } else {
        setLastItemId(undefined);
      }

      if (!firstLoad) {
        setFirstLoad(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isSuccess]);

  const goToDetail = (notification: NotificationItemProps) => {
    const { id, readAt, urlParams } = notification;
    const { conversationId, campaignId } = urlParams || {};

    if (conversationId) {
      router.push(`/dashboard/chat/${conversationId}`);
    } else if (campaignId) {
      router.push(`/dashboard/campaigns/manage/${campaignId}`);
    }

    if (!readAt) {
      readNotifications({
        body: {
          notificationIds: [id],
        },
      });
    }
    closePopover();
  };
  return (
    <List disablePadding>
      {notifications.map((notification) => (
        <ListItem key={notification.id} onClick={() => goToDetail(notification)}>
          <NotificationItem notification={notification} />
        </ListItem>
      ))}
    </List>
  );
}
// ----------------------------------------------------------------------

function NotificationItem({ notification }: Readonly<{ notification: NotificationItemProps }>) {
  const {
    currentLang: { value: lang },
  } = useLocales();
  const { readAt, createdAt } = notification;

  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(!readAt && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {createdAt ? fToNow(createdAt, lang) : fToNow(new Date(), lang)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: NotificationItemProps) {
  const {
    title: notificationTitle = '',
    description = '',
    type = '',
    avatar = null,
  } = notification;

  const title = (
    <Typography variant="subtitle2">
      {notificationTitle}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(description)}
      </Typography>
    </Typography>
  );

  if (type === 'order_placed') {
    return {
      avatar: (
        <img alt={notificationTitle} src="/assets/icons/notification/ic_notification_package.svg" />
      ),
      title,
    };
  }
  if (type === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notificationTitle}
          src="/assets/icons/notification/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (type === 'mail') {
    return {
      avatar: (
        <img alt={notificationTitle} src="/assets/icons/notification/ic_notification_mail.svg" />
      ),
      title,
    };
  }
  if (type === 'chat_message') {
    return {
      avatar: (
        <img alt={notificationTitle} src="/assets/icons/notification/ic_notification_chat.svg" />
      ),
      title,
    };
  }
  return {
    avatar: avatar ? <img alt={notificationTitle} src={avatar} /> : null,
    title,
  };
}
