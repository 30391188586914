import { createSlice } from '@reduxjs/toolkit';

import { isValidToken } from 'src/utils/open-api';

import { AuthState } from 'src/types/auth';

import { coreApi } from '../coreApi';
import { RootState } from '../store';

const storeUserData = ({
  token = '',
  refreshToken = '',
  user = {},
}: {
  token?: string;
  refreshToken?: string;
  user?: any;
}) => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('userId', user.id);
  localStorage.setItem('userStatus', user.status);
};

const handleLogout = (state: any) => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('userId');
  localStorage.removeItem('userLevel');

  state.token = '';
  state.user = null;
};

const slice = createSlice({
  name: 'auth',
  initialState: () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (token && isValidToken(token) && userId) {
      return {
        token,
        user: {
          id: userId,
        },
      } as AuthState;
    }
    return { token: null, user: undefined } as AuthState;
  },
  reducers: {
    logout(state) {
      handleLogout(state);
    },
    tokenReceived(state: any, action) {
      const { payload } = action;
      localStorage.setItem('token', payload.token || '');
      localStorage.setItem('refreshToken', payload.refreshToken || '');

      state.token = payload.token;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(coreApi.endpoints.login.matchFulfilled, (state, action) => {
      const { payload } = action;

      if (payload.user?.role && payload.user.role !== 'admin') {
        return handleLogout(state);
      }

      localStorage.setItem('token', payload.token ?? '');
      localStorage.setItem('refreshToken', payload.refreshToken ?? '');
      localStorage.setItem('userId', payload.user?.id ?? '');
      localStorage.setItem('userLevel', payload.userLevel ?? '');

      state.token = payload.token ?? '';
      if (payload.user) {
        state.user = {
          ...payload.user,
        };
      }
    });

    builder.addMatcher(coreApi.endpoints.getManage.matchFulfilled, (state, action) => {
      const { payload } = action;

      if (payload?.role && payload.role !== 'admin') {
        return handleLogout(state);
      }

      if (state.user && state.user?.id === payload.id) {
        state.user = { ...payload, displayName: `${payload.firstName} ${payload.lastName}` };

        const currentLang = localStorage.getItem('i18nextLng');
        if (payload.lang && payload.lang !== currentLang) {
          localStorage.setItem('i18nextLng', payload.lang);
        }
      }
    });

    builder.addMatcher(coreApi.endpoints.postManageSignup.matchFulfilled, (state, action) => {
      storeUserData(action.payload);

      state.token = action.payload.token ?? '';
      if (action.payload.user) {
        state.user = {
          ...action.payload.user,
        };
      }
    });

    builder.addMatcher(
      coreApi.endpoints.postManageSignupCheckEmail.matchFulfilled,
      (state, action) => {
        state.user = {
          ...state.user,
          status: 'active',
        };
      }
    );
  },
});

export default slice.reducer;
export const { logout, tokenReceived } = slice.actions;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentAuth = (state: RootState) => state.auth;
