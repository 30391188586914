import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import GuestGuard from 'src/guards/guest-guard';
import AuthModernLayout from 'src/layouts/auth/modern';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('src/pages/auth/login-page'));
const RegisterPage = lazy(() => import('src/pages/auth/register-page'));
const ResetPasswordPage = lazy(() => import('src/pages/auth/reset-password-page'));
const NewPasswordPage = lazy(() => import('src/pages/auth/new-password-page'));
const VerifyCodePage = lazy(() => import('src/pages/auth/verify-code-page'));

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      {
        path: 'login',
        element: (
          <AuthModernLayout>
            <LoginPage />
          </AuthModernLayout>
        ),
      },
      {
        path: 'signup',
        element: (
          <AuthModernLayout>
            <RegisterPage />
          </AuthModernLayout>
        ),
      },
      {
        element: (
          <AuthModernCompactLayout>
            <Outlet />
          </AuthModernCompactLayout>
        ),
        children: [
          { path: 'reset-password', element: <ResetPasswordPage /> },
          { path: 'new-password', element: <NewPasswordPage /> },
          { path: 'verify', element: <VerifyCodePage /> },
        ],
      },
    ],
  },
];
