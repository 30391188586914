import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import AuthGuard from 'src/guards/auth-guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const DashboardPage = lazy(() => import('src/pages/dashboard-page'));
const UserListPage = lazy(() => import('src/pages/user-list-page'));
const UserCreatePage = lazy(() => import('src/pages/user-create-page'));
const UserEditPage = lazy(() => import('src/pages/user-edit-page'));
const UserGhostLoginPage = lazy(() => import('src/pages/user-ghost-login-page'));
const BrandListPage = lazy(() => import('src/pages/brand-list-page'));
const BrandCreatePage = lazy(() => import('src/pages/brand-create-page'));
const BrandEditPage = lazy(() => import('src/pages/brand-edit-page'));
const CampaignListPage = lazy(() => import('src/pages/campaign-list-page'));
const CampaignCreatePage = lazy(() => import('src/pages/campaign-create-page'));
const CampaignEditPage = lazy(() => import('src/pages/campaign-edit-page'));
const CampaignReportingPage = lazy(() => import('src/pages/campaign-reporting-page'));
const CampaignManagePage = lazy(() => import('src/pages/campaign-manage-page'));
const ClubsPage = lazy(() => import('src/pages/clubs-page'));
const ClubCreatePage = lazy(() => import('src/pages/club-create-page'));
const ClubEditPage = lazy(() => import('src/pages/club-edit-page'));
const ChatPage = lazy(() => import('src/pages/chat-page'));
const NewsPage = lazy(() => import('src/pages/news-page'));

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <DashboardPage />, index: true },
      { path: 'create-campaigns', element: <CampaignCreatePage /> },
      {
        path: 'users',
        children: [
          { element: <UserListPage />, index: true },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: ':id/edit/:tab', element: <UserEditPage /> },
          { path: ':id/ghost-login', element: <UserGhostLoginPage /> },
        ],
      },
      {
        path: 'brands',
        children: [
          { element: <BrandListPage />, index: true },
          { path: 'new', element: <BrandCreatePage /> },
          { path: ':id/edit', element: <BrandEditPage /> },
        ],
      },
      {
        path: 'campaigns',
        children: [
          { element: <CampaignListPage />, index: true },

          { path: 'reporting/:id', element: <CampaignReportingPage /> },
          { path: 'manage/:id', element: <CampaignManagePage /> },
          { path: ':id/edit', element: <CampaignEditPage /> },
          { path: ':id/edit/:step', element: <CampaignEditPage /> },
        ],
      },
      {
        path: 'clubs',
        children: [
          { element: <ClubsPage />, index: true },
          { path: 'new', element: <ClubCreatePage /> },
          { path: ':id/edit', element: <ClubEditPage /> },
        ],
      },
      {
        path: 'chat',
        children: [
          { element: <ChatPage />, index: true },
          { path: ':conversationKey', element: <ChatPage /> },
        ],
      },
      {
        path: 'news',
        children: [{ element: <NewsPage />, index: true }],
      },
    ],
  },
];
