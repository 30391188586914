import { combineReducers } from 'redux';
import { setupListeners } from '@reduxjs/toolkit/query';
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from 'react-redux';

import auth from './slices/auth';
import { coreApi } from './coreApi';

const combinedReducer = combineReducers({
  [coreApi.reducerPath]: coreApi.reducer,
  auth,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(coreApi.middleware),
    ...options,
  });

const store = createStore();

const { dispatch } = store;

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

const useDispatch = () => useAppDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

setupListeners(dispatch);

export { store, dispatch, useSelector, useDispatch };
